<template>
  <div>
    <section class="hero">
      <div class="wrapper">
        <div class="container-left">
          <h4>Get Vagade for any device</h4>
          <p class="text-medium">
            On this page, you will be able to download the application on the
            device you want.
          </p>
          <a href="#">
            <img src="@/assets/images/picto/download.svg" alt="download" />
            <span class="text-medium" id="downloadBtn"
              >Download for windows</span
            >
          </a>
        </div>
        <div class="container-right">
          <div class="box">
            <img
              id="largeHero"
              src="@/assets/images/download-hero.png"
              alt="app preview"
            />
            <img
              id="smallHero"
              src="@/assets/images/download-hero-small.png"
              alt="app preview"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="devices last">
      <div class="wrapper">
        <div class="card android">
          <h5>Android</h5>
          <img src="@/assets/images/picto/download-android.svg" alt="android" />
          <a href="#">
            <img src="@/assets/images/picto/download.svg" alt="download" />
            <span class="text-medium">Download</span>
          </a>
        </div>
        <div class="card ios">
          <h5>iOS</h5>
          <img src="@/assets/images/picto/download-ios.svg" alt="ios" />
          <a href="#">
            <img src="@/assets/images/picto/download.svg" alt="download" />
            <span class="text-medium">Download</span>
          </a>
        </div>
        <div class="card windows">
          <h5>Windows</h5>
          <img src="@/assets/images/picto/download-windows.svg" alt="windows" />
          <a href="#">
            <img src="@/assets/images/picto/download.svg" alt="download" />
            <span class="text-medium">Download</span>
          </a>
        </div>
        <div class="card mac">
          <h5>Mac</h5>
          <img src="@/assets/images/picto/download-mac.svg" alt="mac" />
          <a href="#">
            <img src="@/assets/images/picto/download.svg" alt="download" />
            <span class="text-medium">Download</span>
          </a>
        </div>
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";

export default {
  components: {
    Ctago,
    Footer,
  },
  name: "Download",
  data() {
    return {
      cta: {
        image: false,
        title: "Can't install?",
        message:
          "If you have any problems, please do not hesitate to contact us. Our technical department will be happy to help you with your questions",
        link: "Contact",
      },
    };
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />
