<template>
  <div class="row" :class="accordionClasses">
    <div class="title" @click="toggleAccordion">
      <h6>{{ faq.question }}</h6>
      <img src="@/assets/images/picto/faq-arrow.svg" alt="arrow" />
    </div>
    <div class="text text-default">{{ faq.answer }}</div>
  </div>
</template>
<script>
export default {
  name: "Faq",
  props: ["faq", "index"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion: function () {
      this.isOpen = !this.isOpen;
    },
  },
  computed: {
    accordionClasses: function () {
      return {
        "is-active": this.isOpen,
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "src/assets/scss/colors";
@import "src/assets/scss/mixins";
.row {
  border: 1px solid $black;
  overflow: hidden;
  padding: 2rem;
  transition: 0.3s ease all;
  @include media("<=tablet") {
    padding: 1.125rem 1rem !important;
  }
  .title {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    img {
      transition: 0.3s ease all;
    }
  }

  .text {
    padding: 0 4rem;
    height: 0;
    opacity: 0;
    transition: 0.3s ease all;
    @include media("<=tablet") {
      padding: 0 1rem;
    }
  }
}

.is-active {
  .title {
    img {
      transform: rotate(180deg);
    }
  }
  .text {
    height: auto;
    padding-top: 4rem;
    padding-bottom: 2rem;
    opacity: 1;
    @include media("<=tablet") {
      padding-top: 1rem;
      padding-bottom: 0rem;
    }
  }
}
</style>