<template>
  <section>
    <div class="container">
      <div class="user signinBx">
        <div class="imgBx">
          <img src="@/assets/images/login.jpg" alt="landscape" />
        </div>
        <div class="formBx">
          <form action="#">
            <h6>Sign in</h6>
            <input type="text" placeholder="Username" />
            <input type="password" placeholder="Password" />
            <input type="image" :src="getImgUrl('arrow-btn')" alt="Login" />
            <p class="signup text-default">
              Don't have an account?
              <a href="#" v-on:click="toggleForm()"
                ><strong>Sign up.</strong></a
              >
            </p>
          </form>
        </div>
      </div>
      <div class="user signupBx">
        <div class="imgBx">
          <img src="@/assets/images/hero-large.jpg" alt="landscape" />
        </div>
        <div class="formBx">
          <form action="#">
            <h6>Create an account</h6>
            <input type="text" placeholder="Username" />
            <input type="text" placeholder="Email Address" />
            <input type="password" placeholder="Create Password" />
            <input type="password" placeholder="Confirm Password" />
            <input type="image" :src="getImgUrl('arrow-btn')" alt="Login" />
            <p class="signin text-default">
              Already have an account?
              <a href="#" v-on:click="toggleForm()"
                ><strong>Sign in.</strong></a
              >
            </p>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Login",
  methods: {
    getImgUrl(name) {
      var images = require.context(
        "../../assets/images/picto/",
        false,
        /\.svg$/
      );
      return images("./" + name + ".svg");
    },
    toggleForm: function () {
      console.log("click");
      var section = document.querySelector("section");
      var container = document.querySelector(".container");
      container.classList.toggle("form-active");
      section.classList.toggle("form-active");
    },
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />