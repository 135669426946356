<template>
  <div>
    <section class="hero center section">
      <div class="wrapper">
        <div class="container-left">
          <h3>TRAVEL AND WORK ALL <span>AROUND THE WORLD !</span></h3>
          <div>
            <p class="text-medium">
              Sometimes it can be difficult to make a choice. If you are
              confused and need more information, our team will be happy to help
              you!
            </p>
            <div class="start-center">
              <router-link :to="{ name: 'Price' }" class="circle">
                <h3>Join us</h3>
                <img
                  src="@/assets/images/picto/arrow-btn.svg"
                  alt="picto arrow"
                />
              </router-link>
            </div>
          </div>
        </div>
        <div class="container-right center">
          <div class="image"></div>
        </div>
      </div>
    </section>
    <section class="video center section">
      <h1>Making</h1>
      <h1>memories</h1>
      <svg
        width="92"
        height="104"
        viewBox="0 0 92 104"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        @click="showNavbar"
      >
        <path
          d="M87 43.3398C93.6667 47.1888 93.6667 56.8113 87 60.6603L15 102.229C8.33331 106.078 -5.12591e-06 101.267 -4.78942e-06 93.5692L-1.15533e-06 10.4308C-8.18835e-07 2.73275 8.33333 -2.07848 15 1.77052L87 43.3398Z"
        />
      </svg>
      <div class="framer">
        <img src="@/assets/images/video-thumbnail.png" alt="Girl in a van" />
      </div>
      <div class="overlay" v-if="showNav" @click="hideNavbar">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Bw-D4LVbReA"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section class="info-nomad section">
      <div class="wrapper">
        <div class="container-left">
          <h5>What is a <br />digital nomad?</h5>
          <p class="text-medium">
            Sometimes it can be difficult to make a choice. If you are confused
            and need more information, our team will be happy to help you!
          </p>
        </div>
        <div class="container-right center">
          <div>
            <img
              src="@/assets/images/info-nomad-01.jpg"
              alt="Landscape with a person"
            />
            <img
              src="@/assets/images/info-nomad-02.jpg"
              alt="Landscape with a person"
            />
            <img
              src="@/assets/images/info-nomad-03.jpg"
              alt="Landscape with a person"
            />
            <img
              src="@/assets/images/info-nomad-04.jpg"
              alt="Landscape with a person"
            />
          </div>
        </div>
        <h5>What is a digital nomad?</h5>
      </div>
    </section>
    <section class="vagade section">
      <div class="intro">
        <h5>We are Vagade</h5>
        <p class="text-big">
          Find out how Vagade can help you in your nomadic adventures and
          improve your lifestyle.
        </p>
        <img
          src="@/assets/images/picto/arrow.svg"
          alt="picto arrow"
          class="arrow-down"
        />
      </div>
      <div class="wrapper">
        <!-- <div class="wrapper"> -->
        <div class="container-left">
          <div class="title">
            <h3>TRAVEL</h3>
            <h3>ORGANISATION</h3>
          </div>
          <p class="text-big">
            Vagade helps you to discover new places and the map will allow you
            to find the perfect places to stay, to work alone or with other
            users.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/vagade-01-01.jpg" alt="obelisk" />
          <img src="@/assets/images/vagade-01-02.jpg" alt="globe" />
          <img src="@/assets/images/vagade-01-03.jpg" alt="plane windows" />
        </div>
      </div>
      <div class="wrapper invert">
        <div class="container-left">
          <div class="title">
            <h3>MEETING PEOPLE</h3>
            <h3>COMMUNITY</h3>
          </div>
          <p class="text-big">
            With the live chat, Vagade allow you to talk with people around you.
            The purpose is to facilitate the creation of relationships with
            people in the same mindset.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/vagade-02-01.jpg" alt="friends working" />
          <img
            src="@/assets/images/vagade-02-02.jpg"
            alt="people around a camp fire"
          />
          <img src="@/assets/images/vagade-02-03.jpg" alt="feet in the air" />
        </div>
      </div>
      <div class="wrapper">
        <div class="container-left">
          <div class="title">
            <h3>PLANNING</h3>
            <h3>PRODUCTIVITY</h3>
          </div>
          <p class="text-big">
            The advantage with Vagade lies in its ability to mix several
            organisational tools into one unique application.
          </p>
        </div>
        <div class="container-right">
          <img
            src="@/assets/images/vagade-03-01.jpg"
            alt="working in the nature"
          />
          <img
            src="@/assets/images/vagade-03-02.jpg"
            alt="man working in the office"
          />
          <img src="@/assets/images/vagade-03-03.jpg" alt="sea landscape" />
        </div>
      </div>
    </section>
    <section class="cities section">
      <div class="marquee">
        <h1>STOCKHOLM</h1>
        <h1>PARIS</h1>
        <h1>BUENOS AIRES</h1>
        <h1>TOKYO</h1>
        <h1>CAPE TOWN</h1>
        <h1>STOCKHOLM</h1>
      </div>
      <div class="marquee">
        <h1>BRUSSELS</h1>
        <h1>MELBOURNE</h1>
        <h1>São Paulo</h1>
        <h1>madrid</h1>
        <h1>LONDON</h1>
        <h1>BRUSSELS</h1>
      </div>
      <div class="marquee">
        <h1>NEW YORK</h1>
        <h1>BERLIN</h1>
        <h1>GRONINGEN</h1>
        <h1>HONG KONG</h1>
        <h1>BUDAPEST</h1>
        <h1>VALENCIA</h1>
      </div>
    </section>

    <section class="join section">
      <h5>2 ways to join us</h5>
      <div class="wrapper join-vagader">
        <div class="box box1">
          <!-- <img src="@/assets/images/join-01.jpg" alt="" /> -->
          <img src="@/assets/images/home-join-01.png" alt="man from the back" />
        </div>
        <div class="text">
          <h3>VAGADER</h3>
          <h1>VAGADER</h1>
          <p class="text-big">
            Sometimes it can be difficult to make a choice. If you are confused
            and need more information, our team will be happy to help you!
          </p>
          <router-link :to="{ name: 'Vagader' }" class="button">
            <img src="@/assets/images/picto/arrow-link.svg" alt="arrow" />
          </router-link>
        </div>
      </div>
      <div class="wrapper join-involver">
        <div class="box box2">
          <!-- <img src="@/assets/images/join-02.jpg" alt="" /> -->
          <img src="@/assets/images/home-join-02.png" alt="barmaid" />
        </div>
        <div class="text">
          <h3>INVOLVER</h3>
          <h1>INVOLVER</h1>
          <p class="text-big">
            Sometimes it can be difficult to make a choice. If you are confused
            and need more information, our team will be happy to help you!
          </p>
          <router-link :to="{ name: 'Involver' }" class="button">
            <img src="@/assets/images/picto/arrow-link.svg" alt="arrow" />
          </router-link>
        </div>
      </div>
    </section>
    <section class="features last">
      <h5>Discover the features</h5>
      <div class="wrapper">
        <div class="container-left">
          <img
            src="@/assets/images/home-features.png"
            alt="preview app on phone"
          />
        </div>
        <div class="container-right">
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img src="@/assets/images/picto/toDo.svg" alt="to do list" />
                  <h6>To do list</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img src="@/assets/images/picto/timer.svg" alt="timer" />
                  <h6>Timer</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img
                    src="@/assets/images/picto/calendar.svg"
                    alt="calendar"
                  />
                  <h6>Calendar</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img src="@/assets/images/picto/maps.svg" alt="maps" />
                  <h6>Maps</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img
                    src="@/assets/images/picto/liveChat.svg"
                    alt="live chat"
                  />
                  <h6>Live chat</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img src="@/assets/images/picto/note.svg" alt="note" />
                  <h6>Notepad</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div class="feature">
            <div class="box">
              <router-link :to="{ name: 'Features' }">
                <div class="content">
                  <img src="@/assets/images/picto/profil.svg" alt="profile" />
                  <h6>Profile</h6>
                </div>
              </router-link>
            </div>
          </div>
          <div>
            <div class="circle">
              <router-link :to="{ name: 'Features' }" class="more">
                <h3>More</h3>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";
import Video from "@/components/Video";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    Ctago,
    Footer,
    Video,
  },
  name: "Home",
  data() {
    return {
      showNav: false,
      width: 0,
      cta: {
        image: true,
        title: "Let's go !",
      },
    };
  },

  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted: function () {
    if (this.width > 768) {
      const homeAnimationBig = this.homeAnimationBig();
    } else {
      const homeAnimationSmall = this.homeAnimationSmall();
    }
  },
  methods: {
    showNavbar() {
      this.showNav = !this.showNav;
    },
    hideNavbar() {
      this.showNav = !this.showNav;
    },
    handleResize() {
      this.width = window.innerWidth;
    },
    homeAnimationBig() {
      ////////////////  GLOBAL START

      // var body = document.body;
      // const changeBG = (color) => {
      //   gsap.to(body, { backgroundColor: color, duration: 1 });
      // };
      // gsap.to(body, {
      //   scrollTrigger: {
      //     trigger: ".video",
      //     start: "-384 center",
      //     end: "center top",
      //     onEnter: () => {
      //       changeBG("#1C0F07");
      //     },
      //     onLeave: () => {
      //       changeBG("#F6F3EF");
      //     },
      //     onEnterBack: () => {
      //       changeBG("#1C0F07");
      //     },
      //     onLeaveBack: () => {
      //       changeBG("#F6F3EF");
      //     },
      //     id: "enter",
      //   },
      // });

      ////////////////  GLOBAL END
      ////////////////  HERO START
      var heroArray = [
        document.querySelector(".hero .image"),
        document.querySelector(".hero h3"),
        document.querySelector(".hero p"),
        document.querySelector(".hero .start-center"),
      ];
      heroArray.forEach((element, index) => {
        gsap.fromTo(
          element,
          { y: 20, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 2,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });
      ////////////////  HERO END
      ////////////////  VIDEO START
      var videoTitles = document.querySelectorAll(".video h1");
      videoTitles.forEach((title, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            title,
            { x: -50 },
            {
              x: 0,
              stagger: 0.1,
              scrollTrigger: {
                scrub: 1,
                trigger: title,
              },
            }
          );
        } else {
          gsap.fromTo(
            title,
            { x: 50 },
            {
              x: 0,
              stagger: 0.1,
              scrollTrigger: {
                scrub: 1,
                trigger: title,
              },
            }
          );
        }

        // const changeText = (color) => {
        //   gsap.to(title, { color: color, duration: 1 });
        // };
        // gsap.to(title, {
        //   scrollTrigger: {
        //     trigger: ".video",
        //     start: "-384 center",
        //     end: "center top",
        //     onEnter: () => {
        //       changeText("#EA9010");
        //     },
        //     onLeave: () => {
        //       changeText("#1C0F07");
        //     },
        //     onEnterBack: () => {
        //       changeText("#EA9010");
        //     },
        //     onLeaveBack: () => {
        //       changeText("#1C0F07");
        //     },
        //     id: "text",
        //   },
        // });
      });
      ////////////////  VIDEO END
      ////////////////  INFO-NOMAD START
      var infoNomad = document.querySelectorAll(".info-nomad img");
      var infoNomadArray = Array.from(infoNomad);
      var infoNomadArray = infoNomadArray.reverse();

      infoNomadArray.forEach((image, index) => {
        console.log(image);
        gsap.fromTo(
          image,
          { opacity: 0 },
          {
            opacity: 1,
            delay: index * 0.4,
            scrollTrigger: {
              trigger: ".info-nomad .wrapper .container-right",
              start: "top center",
            },
          }
        );
      });
      ////////////////  INFO-NOMAD END
      ////////////////  VAGADE START
      var wrappers = gsap.utils.toArray(".vagade .wrapper");
      wrappers.forEach((wrapper) => {
        if (wrapper.classList.contains("invert")) {
          gsap.fromTo(
            wrapper,
            { xPercent: 10, opacity: 0 },
            {
              xPercent: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrapper,
              },
            }
          );
        } else {
          gsap.fromTo(
            wrapper,
            { xPercent: -10, opacity: 0 },
            {
              xPercent: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrapper,
              },
            }
          );
        }
      });
      ////////////////  VAGADE END
      ////////////////  CITIES START
      var marquees = gsap.utils.toArray(".marquee");
      marquees.forEach((marquee) => {
        gsap.fromTo(
          marquee,
          { xPercent: 0 },
          {
            xPercent: -20,
            ease: "sin.in",
            stagger: 0.5,
            scrollTrigger: {
              scrub: 1,
              trigger: marquee,
            },
          }
        );
      });
      ////////////////  CITIES END
      ////////////////  JOIN START
      gsap.fromTo(
        ".box1",
        { xPercent: -30, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 1,
          scrollTrigger: {
            trigger: ".box1",
            start: "70% bottom",
          },
        }
      );
      gsap.fromTo(
        ".box2",
        { xPercent: 30, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 1,
          scrollTrigger: {
            trigger: ".join-involver p",
          },
        }
      );
      ////////////////  JOIN END
      ////////////////  FEATURES START
      ////////////////  FEATURES END
    },
    homeAnimationSmall() {
      // VIDEO START
      var videoTitles = document.querySelectorAll(".video h1");
      videoTitles.forEach((title, index) => {
        gsap.fromTo(
          title,
          { opacity: 0 },
          {
            opacity: 1,
            stagger: 0.1,
            scrollTrigger: {
              trigger: title,
            },
          }
        );
      });
      ////////////////  VIDEO END
      ////////////////  INFO-NOMAD START
      // var infoNomadRight = document.querySelectorAll(
      //   ".info-nomad .container-right"
      // );

      // gsap.fromTo(
      //   infoNomadRight,
      //   { y: 100 },
      //   {
      //     y: 0,
      //     scrollTrigger: {
      //       trigger: ".info-nomad .wrapper .container-right",
      //       start: "top bottom",
      //     },
      //   }
      // );
      ////////////////  INFO-NOMAD END
      ////////////////  VAGADE START
      var wrappers = gsap.utils.toArray(".vagade .wrapper");
      wrappers.forEach((wrapper) => {
        gsap.fromTo(
          wrapper,
          { yPercent: 10, opacity: 0 },
          {
            yPercent: 0,
            opacity: 1,
            ease: "sin.in",
            duration: 2,
            scrollTrigger: {
              trigger: wrapper,
            },
          }
        );
      });
      ////////////////  VAGADE END
      ////////////////  CITIES START
      var marquees = gsap.utils.toArray(".marquee");
      marquees.forEach((marquee) => {
        gsap.fromTo(
          marquee,
          { xPercent: 0 },
          {
            xPercent: -50,
            ease: "sin.in",
            stagger: 0.5,
            scrollTrigger: {
              scrub: 1,
              trigger: marquee,
            },
          }
        );
      });
      ////////////////  CITIES END
      ////////////////  JOIN START
      gsap.fromTo(
        ".box1",
        { yPercent: 10, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 1,
          scrollTrigger: {
            trigger: ".join-vagader",
            start: "center bottom",
          },
        }
      );
      gsap.fromTo(
        ".box2",
        { yPercent: 10, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 1,
          scrollTrigger: {
            trigger: ".join-involver",
            start: "center bottom",
          },
        }
      );
      ////////////////  JOIN END
    },
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />
