<template>
  <div>
    <section class="hero section">
      <div class="wrapper">
        <h2>Organisation</h2>
        <h2>Planning</h2>
        <h2>Communication</h2>
        <img src="@/assets/images/picto/illu-cross.svg" alt="cross" />
        <img src="@/assets/images/picto/illu-shine.svg" alt="shining sun" />
        <img
          src="@/assets/images/picto/illu-border-line.svg"
          alt="line picto"
        />
      </div>
    </section>
    <section class="selection section">
      <h6>Select a feature</h6>
      <h6>All features</h6>
      <div class="wrapper">
        <div class="row">
          <a href="#toDoList"><h4>To do list</h4></a>
          <div class="windows image01"></div>
          <a href="#profile"><h4>Profile</h4></a>
          <a href="#maps"><h4>Maps</h4></a>
        </div>
        <div class="row">
          <div class="windows image02"></div>
          <a href="#timer"><h4>Timer</h4></a>
          <div class="windows image03"></div>
          <a href="#notepad"><h4>Notepad</h4></a>
        </div>
        <div class="row">
          <a href="#calendar"><h4>Calendar</h4></a>
          <a href="#chat"><h4>Live chat</h4></a>
          <div class="windows image04"></div>
        </div>
      </div>
    </section>
    <section class="features last">
      <div class="wrapper" id="chat">
        <div class="container-left">
          <img src="@/assets/images/picto/liveChat.svg" alt="livechat" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>Live chat</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            This feature allow the user to be better organised. There are many
            different possibilities, the user will be able to find the right
            feature which will help him to find out how his environment and
            neighbourhood work. Thanks to you if you are still reading this
            substitute text.
          </p>
        </div>
      </div>
      <div class="wrapper" id="calendar">
        <div class="container-left">
          <img src="@/assets/images/picto/calendar.svg" alt="calendar" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>Calendar</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            A good organisation app does not work without this feature. There
            are many different possibilities, the user will be able to find the
            right feature which will help him to find out how his environment
            and neighbourhood work. Thanks to you if you are still reading this
            substitute text.
          </p>
        </div>
      </div>
      <div class="wrapper" id="notepad">
        <div class="container-left">
          <img src="@/assets/images/picto/note.svg" alt="note" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>Notepad</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            Here is what most people on the move need. There are many different
            possibilities, the user will be able to find the right feature which
            will help him to find out how his environment and neighbourhood
            work. Thanks to you if you are still reading this substitute text.
          </p>
        </div>
      </div>
      <div class="wrapper" id="maps">
        <div class="container-left">
          <img src="@/assets/images/picto/maps.svg" alt="maps" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>Maps</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            A good organisation app does not work without this feature. There
            are many different possibilities, the user will be able to find the
            right feature which will help him to find out how his environment
            and neighbourhood work. Thanks to you if you are still reading this
            substitute text.
          </p>
        </div>
      </div>
      <div class="wrapper" id="profile">
        <div class="container-left">
          <img src="@/assets/images/picto/profil.svg" alt="profile" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>Profile</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            This feature allow the user to be better organised. There are many
            different possibilities, the user will be able to find the right
            feature which will help him to find out how his environment and
            neighbourhood work. Thanks to you if you are still reading this
            substitute text.
          </p>
        </div>
      </div>
      <div class="wrapper" id="timer">
        <div class="container-left">
          <img src="@/assets/images/picto/timer.svg" alt="timer" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>Timer</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            Here is what most people on the move need. There are many different
            possibilities, the user will be able to find the right feature which
            will help him to find out how his environment and neighbourhood
            work. Thanks to you if you are still reading this substitute text.
          </p>
        </div>
      </div>
      <div class="wrapper" id="toDoList">
        <div class="container-left">
          <img src="@/assets/images/picto/toDo.svg" alt="to do list" />
        </div>
        <div class="container-right">
          <div class="title">
            <h5>To do list</h5>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
          <p class="text-medium">
            Euismod elementum nisi quis eleifend quam adipiscing. Sit amet
            tellus cras adipiscing enim eu turpis egestas. Egestas pretium
            aenean pharetra magna ac placerat. Ac turpis egestas sed tempus urna
            et pharetra pharetra.
          </p>
        </div>
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";
gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    Footer,
    Ctago,
  },
  name: "Features",
  data() {
    return {
      width: 0,
      cta: {
        image: true,
        title: "Let's go !",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted: function () {
    if (this.width > 768) {
      const featuresAnimationBig = this.featuresAnimationBig();
    } else {
      const featuresAnimationSmall = this.featuresAnimationSmall();
    }
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    featuresAnimationBig() {
      // FEATURES START
      var wrappers = document.querySelectorAll(".features .wrapper");
      // console.log(viewportWidth);
      wrappers.forEach((wrapper, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            wrappers[index],
            { x: -100, opacity: 0 },
            {
              opacity: 1,
              x: 0,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrappers[index],
                // start: "top center",
                // end: "bottom center",
              },
            }
          );
        } else {
          gsap.fromTo(
            wrappers[index],
            { x: 100, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrappers[index],
                // start: "top center",
                // end: "bottom center",
              },
            }
          );
        }
      });
      // FEATURES END
    },
    featuresAnimationSmall() {
      // FEATURES START
      var wrappers = document.querySelectorAll(".features .wrapper");
      wrappers.forEach((wrapper, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            wrappers[index],
            { x: -10, opacity: 0 },
            {
              opacity: 1,
              x: 0,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrappers[index],
                // start: "top center",
                // end: "bottom center",
              },
            }
          );
        } else {
          gsap.fromTo(
            wrappers[index],
            { x: 10, opacity: 0 },
            {
              x: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrappers[index],
                // start: "top center",
                // end: "bottom center",
              },
            }
          );
        }
      });
      // FEATURES END
    },
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />
