<template>
  <div class="footer">
    <div class="logo wrapper">
      <router-link :to="{ name: 'Home' }">
        <svg
          width="70"
          viewBox="0 0 449 326"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="logo-white-footer"
        >
          <path
            d="M411.135 28.3252C407.661 24.8497 402.276 24.8497 398.802 28.3252L327.414 99.7463L230.492 2.60661C227.018 -0.86887 221.634 -0.86887 218.16 2.60661L121.065 99.7463L49.6765 28.3252C46.2026 24.8497 40.8181 24.8497 37.3442 28.3252C33.8703 31.8006 33.8703 37.1876 37.3442 40.6631L140.692 144.059L166.573 169.951L218.16 221.562C219.723 223.126 221.981 224.168 224.239 224.168C226.497 224.168 228.755 223.3 230.319 221.562L307.786 144.059L327.588 124.248L410.961 40.8369C414.608 37.1876 414.608 31.8006 411.135 28.3252ZM224.239 21.0267L315.255 112.084L301.707 125.639L230.319 54.2175C228.755 52.6535 226.497 51.6109 224.239 51.6109C221.981 51.6109 219.723 52.4797 218.16 54.2175L146.772 125.639L133.223 112.084L224.239 21.0267ZM276 151.357L230.492 105.828C228.929 104.264 226.671 103.222 224.413 103.222C222.155 103.222 219.897 104.091 218.334 105.828L172.652 151.357L159.104 137.803L224.239 72.6375L289.375 137.803L276 151.357ZM224.239 203.142L184.811 163.695L224.239 124.248L263.668 163.695L224.239 203.142Z"
            fill="#F6F3EF"
          />
          <path
            d="M224.239 172.384C229.035 172.384 232.924 168.494 232.924 163.695C232.924 158.896 229.035 155.006 224.239 155.006C219.442 155.006 215.554 158.896 215.554 163.695C215.554 168.494 219.442 172.384 224.239 172.384Z"
            fill="#F6F3EF"
          />
          <path
            d="M276.001 223.995C280.797 223.995 284.685 220.105 284.685 215.306C284.685 210.507 280.797 206.617 276.001 206.617C271.204 206.617 267.316 210.507 267.316 215.306C267.316 220.105 271.204 223.995 276.001 223.995Z"
            fill="#F6F3EF"
          />
          <path
            d="M301.708 198.276C306.504 198.276 310.392 194.386 310.392 189.587C310.392 184.789 306.504 180.899 301.708 180.899C296.911 180.899 293.023 184.789 293.023 189.587C293.023 194.386 296.911 198.276 301.708 198.276Z"
            fill="#F6F3EF"
          />
          <path
            d="M327.588 223.995C332.384 223.995 336.272 220.105 336.272 215.306C336.272 210.507 332.384 206.617 327.588 206.617C322.791 206.617 318.903 210.507 318.903 215.306C318.903 220.105 322.791 223.995 327.588 223.995Z"
            fill="#F6F3EF"
          />
          <path
            d="M121.066 223.995C125.862 223.995 129.75 220.105 129.75 215.306C129.75 210.507 125.862 206.617 121.066 206.617C116.269 206.617 112.381 210.507 112.381 215.306C112.381 220.105 116.269 223.995 121.066 223.995Z"
            fill="#F6F3EF"
          />
          <path
            d="M146.773 198.276C151.569 198.276 155.457 194.386 155.457 189.587C155.457 184.789 151.569 180.899 146.773 180.899C141.976 180.899 138.088 184.789 138.088 189.587C138.088 194.386 141.976 198.276 146.773 198.276Z"
            fill="#F6F3EF"
          />
          <path
            d="M172.653 223.995C177.449 223.995 181.337 220.105 181.337 215.306C181.337 210.507 177.449 206.617 172.653 206.617C167.856 206.617 163.968 210.507 163.968 215.306C163.968 220.105 167.856 223.995 172.653 223.995Z"
            fill="#F6F3EF"
          />
          <path
            d="M69.4778 253.71C73.4727 255.621 75.3834 257.707 75.3834 260.14C75.3834 261.182 74.8623 262.746 73.8201 265.005L46.2027 321.134C45.5079 322.524 44.2921 323.741 42.9025 324.61C41.3393 325.479 39.776 326 38.2128 326H37.1706C35.4337 326 33.8704 325.479 32.4809 324.61C30.9176 323.741 29.8754 322.524 29.1807 321.134L1.56325 265.005C0.521083 262.92 0 261.182 0 260.14C0 257.88 1.91064 255.621 5.90561 253.71C8.16364 252.493 9.72689 251.972 10.9427 251.972C12.1586 251.972 13.0271 252.146 13.5482 252.32C14.0692 252.493 14.764 253.015 15.2851 253.71C15.8062 254.579 16.501 255.448 17.0221 256.317C17.5431 257.359 18.759 260.14 20.8433 264.658C22.9277 269.176 25.1857 274.041 27.4437 279.255C29.8754 284.468 32.1335 289.334 34.2178 293.852C36.3021 298.37 37.518 300.976 37.6917 301.498L57.6665 257.707C58.1876 256.49 58.7087 255.448 59.0561 254.753C60.272 253.015 61.6615 251.972 63.3985 251.972C65.3091 251.972 67.2197 252.667 69.4778 253.71Z"
            fill="#F6F3EF"
          />
          <path
            d="M112.901 294.547L106.127 280.819L99.5267 294.547L92.2315 309.318L87.0207 320.092C86.3259 321.482 85.8048 322.351 85.4574 323.046C85.11 323.567 84.4153 324.262 83.7205 324.957C82.852 325.652 81.8098 325.826 80.594 325.826C79.3781 325.826 77.4675 325.305 75.2095 324.089C71.3882 322.177 69.4775 320.092 69.4775 317.659C69.4775 316.442 69.9986 314.878 71.2145 312.619L98.3108 256.664C99.0056 255.1 100.221 253.884 101.611 253.015C103.174 252.146 104.738 251.625 106.301 251.625C109.948 251.625 112.554 253.362 114.291 256.838L141.387 312.793C142.603 315.052 143.124 316.79 143.124 317.833C143.124 320.265 141.213 322.351 137.392 324.262C135.134 325.479 133.397 326 132.008 326C130.792 326 129.576 325.652 128.881 325.131C128.013 324.61 127.491 323.915 127.144 323.22C126.797 322.525 126.276 321.482 125.581 320.265L120.37 309.492L112.901 294.547Z"
            fill="#F6F3EF"
          />
          <path
            d="M192.801 280.645H210.344C213.992 280.819 216.076 281.688 216.945 283.425C217.639 284.642 217.987 286.553 217.987 288.986V311.055C217.987 313.141 217.118 315.226 215.208 317.138C209.649 322.872 201.138 325.826 190.022 325.826C180.121 325.826 171.437 322.003 164.141 314.531C156.846 307.059 153.199 298.022 153.199 287.248C153.199 276.474 157.02 267.786 164.489 260.661C171.958 253.536 180.816 250.061 190.89 250.061C198.707 250.061 206.176 252.667 213.297 257.881C215.034 259.271 216.076 260.661 216.076 262.399C216.076 263.963 215.381 265.874 213.818 268.133C211.213 271.609 208.781 273.52 206.697 273.52C205.481 273.52 203.396 272.651 200.617 270.74C197.838 268.828 194.538 267.96 190.543 267.96C185.506 267.96 180.99 269.697 176.995 273.347C173 276.996 171.089 281.514 171.089 287.422C171.089 293.157 173.174 298.022 177.169 302.193C181.164 306.19 185.68 308.275 190.717 308.275C194.364 308.275 197.665 307.754 200.617 306.537V295.416H192.627C190.543 295.416 189.153 295.068 188.285 294.547C187.417 294.026 186.895 293.157 186.548 292.114C186.201 291.071 186.201 289.681 186.201 287.944C186.201 286.206 186.374 284.816 186.722 283.773C187.069 282.73 187.59 282.035 188.459 281.514C189.153 280.993 190.717 280.645 192.801 280.645Z"
            fill="#F6F3EF"
          />
          <path
            d="M272.874 294.547L266.1 280.819L259.499 294.547L252.204 309.318L246.993 320.092C246.299 321.482 245.777 322.351 245.43 323.046C245.083 323.567 244.388 324.262 243.693 324.957C242.825 325.652 241.782 325.826 240.567 325.826C239.351 325.826 237.44 325.305 235.182 324.089C231.361 322.177 229.45 320.092 229.45 317.659C229.45 316.442 229.971 314.878 231.187 312.619L258.283 256.664C258.978 255.1 260.194 253.884 261.584 253.015C263.147 252.146 264.71 251.625 266.273 251.625C269.921 251.625 272.526 253.362 274.263 256.838L301.36 312.793C302.575 315.052 303.097 316.79 303.097 317.833C303.097 320.265 301.186 322.351 297.365 324.262C295.107 325.479 293.37 326 291.98 326C290.764 326 289.548 325.652 288.854 325.131C287.985 324.61 287.464 323.915 287.117 323.22C286.769 322.525 286.248 321.482 285.553 320.265L280.343 309.492L272.874 294.547Z"
            fill="#F6F3EF"
          />
          <path
            d="M323.593 252.494L342.178 252.667C351.905 252.667 360.416 256.143 367.711 263.094C375.006 270.045 378.654 278.734 378.654 288.812C378.654 299.065 375.006 307.754 367.885 315.052C360.763 322.351 352.079 326 341.831 326H323.593C319.598 326 316.992 325.131 315.95 323.567C315.082 322.177 314.734 320.092 314.734 317.138V261.356C314.734 259.792 314.734 258.576 314.908 257.881C315.082 257.186 315.255 256.317 315.777 255.274C316.645 253.536 319.25 252.494 323.593 252.494ZM355.031 301.845C358.853 298.196 360.763 293.852 360.763 288.291C360.763 282.904 358.853 278.386 355.031 274.737C351.21 271.087 346.868 269.35 341.831 269.35H332.278V307.232H342.004C346.868 307.232 351.21 305.495 355.031 301.845Z"
            fill="#F6F3EF"
          />
          <path
            d="M408.877 308.275H439.794C441.358 308.275 442.4 308.275 443.268 308.449C443.963 308.623 445.005 308.796 445.874 309.318C447.611 310.187 448.653 312.793 448.653 317.138C448.653 321.829 447.437 324.61 445.005 325.305C443.789 325.652 442.052 325.826 439.794 325.826H400.192C395.502 325.826 392.723 324.61 392.028 322.003C391.681 320.961 391.507 319.223 391.507 316.964V261.182C391.507 257.707 392.202 255.448 393.418 254.231C394.634 253.015 397.066 252.32 400.713 252.32H440.142C441.705 252.32 442.747 252.32 443.616 252.494C444.31 252.667 445.353 252.841 446.221 253.362C447.958 254.231 449 256.838 449 261.182C449 265.874 447.784 268.655 445.353 269.35C444.137 269.697 442.4 269.871 440.142 269.871H409.398V279.95H425.725C427.288 279.95 428.331 279.95 429.199 280.124C429.894 280.297 430.936 280.471 431.804 280.993C433.541 281.861 434.584 284.468 434.584 288.812C434.584 293.504 433.368 296.285 430.762 296.98C429.546 297.327 427.809 297.501 425.551 297.501H409.398V308.275H408.877Z"
            fill="#F6F3EF"
          />
        </svg>
        <svg
          width="70"
          viewBox="0 0 449 326"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="logo-black-footer"
        >
          <path
            d="M411.135 28.3252C407.661 24.8497 402.276 24.8497 398.802 28.3252L327.414 99.7463L230.492 2.60661C227.018 -0.86887 221.634 -0.86887 218.16 2.60661L121.065 99.7463L49.6765 28.3252C46.2026 24.8497 40.8181 24.8497 37.3442 28.3252C33.8703 31.8006 33.8703 37.1876 37.3442 40.6631L140.692 144.059L166.573 169.951L218.16 221.562C219.723 223.126 221.981 224.168 224.239 224.168C226.497 224.168 228.755 223.3 230.319 221.562L307.786 144.059L327.588 124.248L410.961 40.8369C414.608 37.1876 414.608 31.8006 411.135 28.3252ZM224.239 21.0267L315.255 112.084L301.707 125.639L230.319 54.2175C228.755 52.6535 226.497 51.6109 224.239 51.6109C221.981 51.6109 219.723 52.4797 218.16 54.2175L146.772 125.639L133.223 112.084L224.239 21.0267ZM276 151.357L230.492 105.828C228.929 104.264 226.671 103.222 224.413 103.222C222.155 103.222 219.897 104.091 218.334 105.828L172.652 151.357L159.104 137.803L224.239 72.6375L289.375 137.803L276 151.357ZM224.239 203.142L184.811 163.695L224.239 124.248L263.668 163.695L224.239 203.142Z"
            fill="#1C0F07"
          />
          <path
            d="M224.239 172.384C229.035 172.384 232.924 168.494 232.924 163.695C232.924 158.896 229.035 155.006 224.239 155.006C219.442 155.006 215.554 158.896 215.554 163.695C215.554 168.494 219.442 172.384 224.239 172.384Z"
            fill="#1C0F07"
          />
          <path
            d="M276.001 223.995C280.797 223.995 284.685 220.105 284.685 215.306C284.685 210.507 280.797 206.617 276.001 206.617C271.204 206.617 267.316 210.507 267.316 215.306C267.316 220.105 271.204 223.995 276.001 223.995Z"
            fill="#1C0F07"
          />
          <path
            d="M301.708 198.276C306.504 198.276 310.392 194.386 310.392 189.587C310.392 184.789 306.504 180.899 301.708 180.899C296.911 180.899 293.023 184.789 293.023 189.587C293.023 194.386 296.911 198.276 301.708 198.276Z"
            fill="#1C0F07"
          />
          <path
            d="M327.588 223.995C332.384 223.995 336.272 220.105 336.272 215.306C336.272 210.507 332.384 206.617 327.588 206.617C322.791 206.617 318.903 210.507 318.903 215.306C318.903 220.105 322.791 223.995 327.588 223.995Z"
            fill="#1C0F07"
          />
          <path
            d="M121.066 223.995C125.862 223.995 129.75 220.105 129.75 215.306C129.75 210.507 125.862 206.617 121.066 206.617C116.269 206.617 112.381 210.507 112.381 215.306C112.381 220.105 116.269 223.995 121.066 223.995Z"
            fill="#1C0F07"
          />
          <path
            d="M146.773 198.276C151.569 198.276 155.457 194.386 155.457 189.587C155.457 184.789 151.569 180.899 146.773 180.899C141.976 180.899 138.088 184.789 138.088 189.587C138.088 194.386 141.976 198.276 146.773 198.276Z"
            fill="#1C0F07"
          />
          <path
            d="M172.653 223.995C177.449 223.995 181.337 220.105 181.337 215.306C181.337 210.507 177.449 206.617 172.653 206.617C167.856 206.617 163.968 210.507 163.968 215.306C163.968 220.105 167.856 223.995 172.653 223.995Z"
            fill="#1C0F07"
          />
          <path
            d="M69.4778 253.71C73.4727 255.621 75.3834 257.707 75.3834 260.14C75.3834 261.182 74.8623 262.746 73.8201 265.005L46.2027 321.134C45.5079 322.524 44.2921 323.741 42.9025 324.61C41.3393 325.479 39.776 326 38.2128 326H37.1706C35.4337 326 33.8704 325.479 32.4809 324.61C30.9176 323.741 29.8754 322.524 29.1807 321.134L1.56325 265.005C0.521083 262.92 0 261.182 0 260.14C0 257.88 1.91064 255.621 5.90561 253.71C8.16364 252.493 9.72689 251.972 10.9427 251.972C12.1586 251.972 13.0271 252.146 13.5482 252.32C14.0692 252.493 14.764 253.015 15.2851 253.71C15.8062 254.579 16.501 255.448 17.0221 256.317C17.5431 257.359 18.759 260.14 20.8433 264.658C22.9277 269.176 25.1857 274.041 27.4437 279.255C29.8754 284.468 32.1335 289.334 34.2178 293.852C36.3021 298.37 37.518 300.976 37.6917 301.498L57.6665 257.707C58.1876 256.49 58.7087 255.448 59.0561 254.753C60.272 253.015 61.6615 251.972 63.3985 251.972C65.3091 251.972 67.2197 252.667 69.4778 253.71Z"
            fill="#1C0F07"
          />
          <path
            d="M112.901 294.547L106.127 280.819L99.5267 294.547L92.2315 309.318L87.0207 320.092C86.3259 321.482 85.8048 322.351 85.4574 323.046C85.11 323.567 84.4153 324.262 83.7205 324.957C82.852 325.652 81.8098 325.826 80.594 325.826C79.3781 325.826 77.4675 325.305 75.2095 324.089C71.3882 322.177 69.4775 320.092 69.4775 317.659C69.4775 316.442 69.9986 314.878 71.2145 312.619L98.3108 256.664C99.0056 255.1 100.221 253.884 101.611 253.015C103.174 252.146 104.738 251.625 106.301 251.625C109.948 251.625 112.554 253.362 114.291 256.838L141.387 312.793C142.603 315.052 143.124 316.79 143.124 317.833C143.124 320.265 141.213 322.351 137.392 324.262C135.134 325.479 133.397 326 132.008 326C130.792 326 129.576 325.652 128.881 325.131C128.013 324.61 127.491 323.915 127.144 323.22C126.797 322.525 126.276 321.482 125.581 320.265L120.37 309.492L112.901 294.547Z"
            fill="#1C0F07"
          />
          <path
            d="M192.801 280.645H210.344C213.992 280.819 216.076 281.688 216.945 283.425C217.639 284.642 217.987 286.553 217.987 288.986V311.055C217.987 313.141 217.118 315.226 215.208 317.138C209.649 322.872 201.138 325.826 190.022 325.826C180.121 325.826 171.437 322.003 164.141 314.531C156.846 307.059 153.199 298.022 153.199 287.248C153.199 276.474 157.02 267.786 164.489 260.661C171.958 253.536 180.816 250.061 190.89 250.061C198.707 250.061 206.176 252.667 213.297 257.881C215.034 259.271 216.076 260.661 216.076 262.399C216.076 263.963 215.381 265.874 213.818 268.133C211.213 271.609 208.781 273.52 206.697 273.52C205.481 273.52 203.396 272.651 200.617 270.74C197.838 268.828 194.538 267.96 190.543 267.96C185.506 267.96 180.99 269.697 176.995 273.347C173 276.996 171.089 281.514 171.089 287.422C171.089 293.157 173.174 298.022 177.169 302.193C181.164 306.19 185.68 308.275 190.717 308.275C194.364 308.275 197.665 307.754 200.617 306.537V295.416H192.627C190.543 295.416 189.153 295.068 188.285 294.547C187.417 294.026 186.895 293.157 186.548 292.114C186.201 291.071 186.201 289.681 186.201 287.944C186.201 286.206 186.374 284.816 186.722 283.773C187.069 282.73 187.59 282.035 188.459 281.514C189.153 280.993 190.717 280.645 192.801 280.645Z"
            fill="#1C0F07"
          />
          <path
            d="M272.874 294.547L266.1 280.819L259.499 294.547L252.204 309.318L246.993 320.092C246.299 321.482 245.777 322.351 245.43 323.046C245.083 323.567 244.388 324.262 243.693 324.957C242.825 325.652 241.782 325.826 240.567 325.826C239.351 325.826 237.44 325.305 235.182 324.089C231.361 322.177 229.45 320.092 229.45 317.659C229.45 316.442 229.971 314.878 231.187 312.619L258.283 256.664C258.978 255.1 260.194 253.884 261.584 253.015C263.147 252.146 264.71 251.625 266.273 251.625C269.921 251.625 272.526 253.362 274.263 256.838L301.36 312.793C302.575 315.052 303.097 316.79 303.097 317.833C303.097 320.265 301.186 322.351 297.365 324.262C295.107 325.479 293.37 326 291.98 326C290.764 326 289.548 325.652 288.854 325.131C287.985 324.61 287.464 323.915 287.117 323.22C286.769 322.525 286.248 321.482 285.553 320.265L280.343 309.492L272.874 294.547Z"
            fill="#1C0F07"
          />
          <path
            d="M323.593 252.494L342.178 252.667C351.905 252.667 360.416 256.143 367.711 263.094C375.006 270.045 378.654 278.734 378.654 288.812C378.654 299.065 375.006 307.754 367.885 315.052C360.763 322.351 352.079 326 341.831 326H323.593C319.598 326 316.992 325.131 315.95 323.567C315.082 322.177 314.734 320.092 314.734 317.138V261.356C314.734 259.792 314.734 258.576 314.908 257.881C315.082 257.186 315.255 256.317 315.777 255.274C316.645 253.536 319.25 252.494 323.593 252.494ZM355.031 301.845C358.853 298.196 360.763 293.852 360.763 288.291C360.763 282.904 358.853 278.386 355.031 274.737C351.21 271.087 346.868 269.35 341.831 269.35H332.278V307.232H342.004C346.868 307.232 351.21 305.495 355.031 301.845Z"
            fill="#1C0F07"
          />
          <path
            d="M408.877 308.275H439.794C441.358 308.275 442.4 308.275 443.268 308.449C443.963 308.623 445.005 308.796 445.874 309.318C447.611 310.187 448.653 312.793 448.653 317.138C448.653 321.829 447.437 324.61 445.005 325.305C443.789 325.652 442.052 325.826 439.794 325.826H400.192C395.502 325.826 392.723 324.61 392.028 322.003C391.681 320.961 391.507 319.223 391.507 316.964V261.182C391.507 257.707 392.202 255.448 393.418 254.231C394.634 253.015 397.066 252.32 400.713 252.32H440.142C441.705 252.32 442.747 252.32 443.616 252.494C444.31 252.667 445.353 252.841 446.221 253.362C447.958 254.231 449 256.838 449 261.182C449 265.874 447.784 268.655 445.353 269.35C444.137 269.697 442.4 269.871 440.142 269.871H409.398V279.95H425.725C427.288 279.95 428.331 279.95 429.199 280.124C429.894 280.297 430.936 280.471 431.804 280.993C433.541 281.861 434.584 284.468 434.584 288.812C434.584 293.504 433.368 296.285 430.762 296.98C429.546 297.327 427.809 297.501 425.551 297.501H409.398V308.275H408.877Z"
            fill="#1C0F07"
          />
        </svg>
      </router-link>
      <p class="text-default">© Copyright 2021</p>
    </div>
    <div class="sitemap wrapper">
      <h6>Sitemap</h6>
      <div class="box">
        <ul>
          <li>
            <router-link :to="{ name: 'Vagader' }" class="text-footer"
              >Vagader</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Involver' }" class="text-footer"
              >Involver</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Story' }" class="text-footer"
              >Story</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Contact' }" class="text-footer"
              >Contact</router-link
            >
          </li>
        </ul>
        <ul>
          <li>
            <router-link :to="{ name: 'Price' }" class="text-footer"
              >Price</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Features' }" class="text-footer"
              >Features</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Download' }" class="text-footer"
              >Download</router-link
            >
          </li>
          <li>
            <router-link :to="{ name: 'Login' }" class="text-footer"
              >Sign up</router-link
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="legal wrapper">
      <h6>Legal</h6>
      <ul>
        <li>
          <a href="#" class="text-footer">Term & conditions</a>
        </li>
        <li>
          <a href="#" class="text-footer">Privacy Policy</a>
        </li>
        <li>
          <a href="#" class="text-footer">Cookies</a>
        </li>
      </ul>
    </div>
    <div class="in-touch wrapper">
      <h6>Get in touch</h6>
      <ul>
        <li>
          <a href="#" class="text-footer">info@vagade.world</a>
        </li>
        <li>
          <a href="#" class="text-footer">04.22.52.10.10</a>
        </li>
      </ul>
    </div>
    <div class="follow wrapper">
      <h6>Follow us</h6>
      <ul>
        <li><a href="#" class="text-footer">Instagram</a></li>
        <li><a href="#" class="text-footer">Twitter</a></li>
        <li><a href="#" class="text-footer">YouTube</a></li>
        <li><a href="#" class="text-footer">TikTok</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";
@import "src/assets/scss/_variables.scss";
@import "src/assets/scss/_mixins.scss";

.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 6rem;
  @include media("<=tablet") {
    background: $black;
    margin-left: -1.125rem;
    margin-right: -1.125rem;
    padding: 1.125rem;
    flex-direction: column;
    gap: 4rem;
    margin-bottom: 0;
  }
  .wrapper {
    h6 {
      margin-bottom: 2rem;
      @include media("<=tablet") {
        color: $white;
      }
    }
    ul {
      li {
        margin-bottom: 0.5rem;
        list-style: none;
        a {
          transition: 0.3s ease;
          &:hover {
            color: $primary;
          }
          @include media("<=tablet") {
            color: $dark-grey;
          }
        }
      }
    }
    &.logo {
      display: flex;
      flex-flow: column wrap;
      justify-content: space-between;
      @include media("<=tablet") {
        flex-flow: row wrap;
      }
      a {
        svg {
          &#logo-white-footer {
            display: none;
            @include media("<=tablet") {
              display: block;
            }
          }
          &#logo-black-footer {
            display: block;
            @include media("<=tablet") {
              display: none;
            }
          }
        }
      }
      p {
        color: $grey;
        @include media("<=tablet") {
          display: flex;
          align-items: flex-end;
        }
      }
    }
    &.sitemap {
      .box {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        column-gap: 2rem;
        @include media("<=tablet") {
          justify-content: flex-start;
          gap: 4rem;
        }
      }
    }
  }
}
</style>