<template>
  <div class="video">
    <div class="overlay">
      <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Bw-D4LVbReA"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
};
</script>

<!-- <style src="./style.scss" lang="scss" scoped /> -->
<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";
@import "src/assets/scss/_breakpoints";
@import "src/assets/scss/variables";
.overlay {
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  display: grid;
  place-content: center;
}
</style>