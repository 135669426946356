<template>
  <div>
    <section class="hero">
      <h1>CONTACT US</h1>
      <img src="@/assets/images/contact-hero.png" alt="woman in a car" />
    </section>
    <section class="navigation">
      <div class="wrapper">
        <div class="button">
          <a href="#write-us"><h3>Write us</h3></a>
        </div>
        <div class="button">
          <a href="#contact"><h3>Contact</h3></a>
        </div>
        <div class="button">
          <a href="#follow"><h3>Follow us</h3></a>
        </div>
        <div class="button">
          <a href="#faq"><h3>FAQ</h3></a>
        </div>
      </div>
    </section>
    <section class="write-us section" id="write-us">
      <h5>Write us</h5>
      <h6>Write us</h6>
      <div class="wrapper">
        <div class="container-left">
          <label class="container">
            <h6>I have a question about...</h6>
            <input type="radio" checked="checked" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="container">
            <h6>Can I join the team ?</h6>
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="container">
            <h6>Press, partnership...</h6>
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
          <label class="container">
            <h6>A love note for you</h6>
            <input type="radio" name="radio" />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="container-right">
          <form action="#">
            <p class="row">
              <label for="full-name" class="text-default">Full name</label>
              <input
                type="text"
                class="text-default"
                name="full-name"
                placeholder="John Doe"
              />
            </p>
            <p class="row">
              <label for="email" class="text-default">Mail address</label>
              <input
                type="text"
                class="text-default"
                name="email"
                placeholder="email@hello.world"
              />
            </p>
            <p class="row">
              <label for="email" class="text-default">Phone number</label>
              <input
                type="tel"
                class="text-default"
                name="phone"
                placeholder="0244856 84 85"
              />
            </p>
            <p class="row">
              <label for="email" class="text-default">Your message</label>
              <te
                type="tel"
                class="text-default"
                name="phone"
                placeholder="0244856 84 85"
              />
              <textarea
                rows="4"
                cols="50"
                placeholder="Say hi! Ni ankaŭ parolas Esperanton!"
              ></textarea>
            </p>
            <div class="box-btn">
              <router-link :to="{ name: 'Download' }">
                <img src="@/assets/images/picto/arrow.svg" alt="arrow" />
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </section>
    <section class="map section" id="contact">
      <h5>Contact</h5>
      <h6>Contact</h6>
      <h4>On the map</h4>
      <div class="wrapper">
        <div class="container-left">
          <h4>On the map</h4>
          <div class="address">
            <h6>Address</h6>
            <p class="text-medium">No Name Avenue, 404</p>
            <p class="text-medium">1410 Waterloo, Nethergium</p>
          </div>
          <div class="mails">
            <h6>Mails</h6>
            <div class="row">
              <p class="text-medium">Information</p>
              <p class="text-medium">
                <a
                  href="mailto:
jonathan.veirman.9235@stu.he-ferrer.eu?subject=May I ask you...?"
                  >info@vagade.com</a
                >
              </p>
            </div>
            <div class="row">
              <p class="text-medium">Press</p>
              <p class="text-medium">
                <a
                  href="mailto:
jonathan.veirman.9235@stu.he-ferrer.eu?subject=Partnership?"
                  >press@vagade.com</a
                >
              </p>
            </div>
            <div class="row">
              <p class="text-medium">Jobs</p>
              <p class="text-medium">
                <a
                  href="mailto:
jonathan.veirman.9235@stu.he-ferrer.eu?subject=I want to join you"
                  >jobs@vagade.com</a
                >
              </p>
            </div>
          </div>
        </div>
        <div class="container-right">
          <!-- <div class="map"></div> -->
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1560.3629665211838!2d6.555729078582089!3d53.216118157611106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c9cd4e9f4e3a0d%3A0xc56e182fbac4bb3e!2sAweg%2010%2C%209718%20CS%20Groningen!5e0!3m2!1sfr!2snl!4v1622493723891!5m2!1sfr!2snl"
            width="635"
            height="406"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </section>
    <section class="social" id="follow">
      <h5>Follow us</h5>
      <h6>Follow us</h6>
      <div class="wrapper">
        <a href="https://www.instagram.com/" target="_blank"
          ><h1>INSTAGRAM</h1></a
        >
        <span>INSTAGRAM</span>
        <img
          src="@/assets/images/contact-insta.png"
          alt="picture of building"
        />
      </div>
      <div class="wrapper">
        <a href="https://www.twitter.com/" target="_blank"><h1>TWITTER</h1></a>
        <span>TWITTER</span>
        <img src="@/assets/images/contact-twitter.png" alt="desert" />
      </div>
      <div class="wrapper">
        <a href="https://www.youtube.com/" target="_blank"><h1>YOUTUBE</h1></a>
        <span>YOUTUBE</span>
        <img
          src="@/assets/images/contact-youtube.png"
          alt="picture of girls in a caddy"
        />
      </div>
    </section>
    <section class="faq last" id="faq">
      <h5>FAQ</h5>
      <h6>FAQ</h6>
      <h4>Do you have any questions?</h4>
      <div class="questions">
        <Faq v-for="(faq, i) in faqs" :faq="faq" :index="i" :key="i" />
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";
import Faq from "@/components/Faq";

export default {
  components: {
    Ctago,
    Footer,
    Faq,
  },
  name: "Contact",
  data() {
    return {
      faqs: [
        {
          question: "How to work abroad with your feet in the sand?",
          answer:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
          open: false,
        },
        {
          question: "What is a digital nomad?",
          answer:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
          open: false,
        },
        {
          question: "How to change my profile picture?",
          answer:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
          open: false,
        },
        {
          question:
            "Why is the application not yet as successful as it should be?",
          answer:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
          open: false,
        },
        {
          question: "I hope to receive a distinction for this work?",
          answer:
            "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text.",
          open: false,
        },
      ],
      cta: {
        image: false,
        title: "More information?",
        message:
          "Do not hesitate to contact us if you have not found the answer to your question.",
        link: "here",
      },
    };
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />
