<template>
  <div>
    <section class="hero">
      <div class="wrapper">
        <h2>Vagade Pricing</h2>
        <h5>Find your future favourite pack here!</h5>
        <h6>Find your future favourite pack here!</h6>
      </div>
    </section>
    <section class="packs last">
      <div class="switch">
        <h6 class="monthly">Monthly</h6>
        <div class="input">
          <input type="checkbox" id="switch" />
          <label for="switch" @click="changement"></label>
        </div>
        <h6 class="yearly">Yearly</h6>
      </div>
      <div class="wrapper">
        <div class="card vagader">
          <div class="header">
            <h5>Vagader</h5>
            <p class="text-medium">Free pack for the new arrivals</p>
          </div>
          <div class="price">
            <h4>{{ prices[0] }}</h4>
            <p class="text-default">/ per month</p>
          </div>
          <router-link :to="{ name: 'Login' }" class="button">
            <div class="content">
              <p class="text-medium">Register</p>
              <img src="@/assets/images/picto/arrow-btn.svg" alt="arrow" />
            </div>
          </router-link>
          <router-link :to="{ name: 'Vagader' }" class="link">
            <p class="text-default">Learn more about Vagader</p>
            <img src="@/assets/images/picto/arrow-link.svg" alt="link" />
          </router-link>
          <hr />
          <div class="features">
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>To do list</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Calendar</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Maps</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Notepad</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Timer</h6>
            </div>
            <div class="row disabled">
              <img src="@/assets/images/picto/dislike.svg" alt="dislike" />
              <h6>Live chat</h6>
            </div>
            <div class="row disabled">
              <img src="@/assets/images/picto/dislike.svg" alt="dislike" />
              <h6>Profil</h6>
            </div>
          </div>
        </div>
        <div class="card vagaderPlus">
          <div class="header">
            <h5>Vagader</h5>
            <p class="text-medium">The most used pack for traveller</p>
          </div>
          <div class="price">
            <h4>{{ val }}</h4>
            <p class="text-default">/ per month</p>
          </div>
          <router-link :to="{ name: 'Login' }" class="button">
            <div class="content">
              <p class="text-medium">Upgrade</p>
              <img src="@/assets/images/picto/arrow-btn.svg" alt="arrow" />
            </div>
          </router-link>
          <router-link :to="{ name: 'Vagader' }" class="link">
            <p class="text-default">Learn more about Vagader</p>
            <img src="@/assets/images/picto/arrow-link.svg" alt="link" />
          </router-link>
          <hr />
          <div class="features">
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>To do list</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Calendar</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Maps</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Notepad</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Timer</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Live chat</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Profil</h6>
            </div>
          </div>
        </div>
        <div class="card involver">
          <div class="header">
            <h5>Involver</h5>
            <p class="text-medium">
              The pack to host, feed, share with the Vagader
            </p>
          </div>
          <div class="price">
            <h4>{{ prices[2] }}</h4>
            <p class="text-default">/ per month</p>
          </div>
          <router-link :to="{ name: 'Login' }" class="button">
            <div class="content">
              <p class="text-medium">Upgrade</p>
              <img src="@/assets/images/picto/arrow-btn.svg" alt="arrow" />
            </div>
          </router-link>
          <router-link :to="{ name: 'Involver' }" class="link">
            <p class="text-default">Learn more about Involver</p>
            <img src="@/assets/images/picto/arrow-link.svg" alt="link" />
          </router-link>
          <hr />
          <div class="features">
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Profil</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Maps</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Add places</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Calendar</h6>
            </div>
            <div class="row">
              <img src="@/assets/images/picto/like.svg" alt="like" />
              <h6>Live chat</h6>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";

export default {
  components: {
    Footer,
    Ctago,
  },
  name: "Price",
  data() {
    return {
      toggle: true,
      prices: ["FREE", "$19", "$10"],
      val: "$19",
      cta: {
        image: false,
        title: "Let's go !",
        message:
          "Sometimes it can be difficult to make a choice. If you are confused and need more information, our team will be happy to help you!",
        link: "Contact",
      },
    };
  },
  methods: {
    changement() {
      this.toggle = !this.toggle;
      if (this.toggle) {
        // this.prices = ["FREE", "$13", "$5"];
        this.val = "$13";
      } else {
        // this.prices = ["FREE", "$19", "$10"];
        this.val = "$19";
      }
    },
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />
