<template>
  <div class="cta">
    <div class="wrapper">
      <img
        v-if="cta.image"
        src="@/assets/images/cta-go-true.jpg"
        alt="backpacking"
      />
      <img v-else src="@/assets/images/cta-go-false.jpg" alt="man working" />
      <svg
        width="384"
        height="246"
        viewBox="0 0 384 246"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M361.578 187.576C358.631 195.309 351.16 200.981 340.004 204.556C328.855 208.128 314.107 209.575 296.768 208.914C262.096 207.591 217.181 197.841 170.252 179.962C123.324 162.083 83.3073 139.477 56.5441 117.394C43.1605 106.351 33.1137 95.4574 27.1676 85.372C21.2184 75.2811 19.4151 66.0751 22.3613 58.3419C25.3075 50.6086 32.7791 44.9361 43.9345 41.3618C55.084 37.7894 69.8323 36.3422 87.171 37.0037C121.843 38.3266 166.758 48.0769 213.686 65.9555C260.615 83.8341 300.632 106.441 327.395 128.524C340.778 139.567 350.825 150.46 356.771 160.546C362.72 170.636 364.524 179.842 361.578 187.576Z"
          stroke="#1C0F07"
        />
      </svg>
    </div>
    <h3>{{ cta.title }}</h3>
    <p v-if="cta.message" class="text-medium">{{ cta.message }}</p>
    <p v-else class="text-medium">
      It’s time to get your own <strong>access</strong> !
    </p>
    <router-link v-if="cta.link == 'Contact'" :to="{ name: 'Contact' }">
      <img src="@/assets/images/picto/arrow.svg" alt="arrow" />
    </router-link>
    <a v-else-if="cta.link == 'here'" href="#write-us">
      <img src="@/assets/images/picto/arrow.svg" alt="arrow" />
    </a>
    <router-link v-else :to="{ name: 'Download' }">
      <img src="@/assets/images/picto/arrow.svg" alt="arrow" />
    </router-link>
  </div>
</template>

<script>
export default {
  name: "Ctago",
  props: ["cta"],
};
</script>

<!-- <style src="./style.scss" lang="scss" scoped /> -->
<style lang="scss" scoped>
@import "src/assets/scss/_colors.scss";
@import "src/assets/scss/_breakpoints";
@import "src/assets/scss/variables";
.cta {
  text-align: center;
  margin-bottom: 24rem;
  text-transform: none;
  @include media("<=tablet") {
    margin-bottom: 12rem;
  }
  h3 {
    margin-top: 1rem;
    text-transform: initial;
  }
}
.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  place-items: center;
  > * {
    grid-area: 1 / 1;
  }
  img {
    border-radius: $windows;
    @include media("<=tablet") {
      width: 100%;
      max-width: 200px;
      height: auto;
    }
    @include media("<=phone") {
      width: 100%;
      max-width: 150px;
      height: auto;
    }
  }
  svg {
    z-index: 1;
    @include media("<=tablet") {
      width: 100%;
      max-width: 300px;
      height: auto;
    }
    @include media("<=phone") {
      width: 100%;
      max-width: 250px;
      height: auto;
    }
  }
}
p {
  margin-bottom: 1.5rem;
  max-width: 40rem;
  margin-left: auto;
  margin-right: auto;
}
a {
  display: grid;
  place-items: center;
  max-width: 12rem;
  border: 1px solid $black;
  border-radius: $circle;
  margin: 0 auto;
  transition: 0.3s ease, border 0.1s;
  transition-delay: 0.01s;
  @include media("<=phone") {
    width: 100%;
    max-width: 8rem;
    height: auto;
  }
  &:hover {
    transform: scale(0.9);

    img {
      transform: rotate(-90deg) scale(1.5);
    }
  }
  &:active {
    border: 1px solid $primary;
  }

  img {
    transition: 0.3s ease;
    transform: rotate(-90deg);
  }
}
</style>