<template>
  <div>
    <section class="hero section">
      <div class="wrapper">
        <!-- <img src="@/assets/images/vagade-01-01.jpg" alt="" />
        <img src="@/assets/images/vagade-01-01.jpg" alt="" /> -->
        <div class="box"><h1>VAGADER</h1></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
      </div>
    </section>
    <section class="intro section">
      <h5 class="intro_title">
        Discover new places, shape your own dream life, create moments...
      </h5>
      <p class="text-medium">
        You have a workspace, a cosy café, a sandwich shop, a place to sleep, a
        parking space, a perfect bar to spend the evening and you want to help
        and welcome people passing through your neighbourhood. Your role will be
        Involver.
      </p>
      <p class="text-medium">
        Use the application to promote your services and allow Vagaders to
        easily find their way around and enjoy the opportunity to meet new
        people.
      </p>
    </section>
    <section class="art section">
      <div class="container-left">
        <img src="@/assets/images/picto/illu-sunrise.svg" alt="sunrise picto" />
      </div>
      <div class="container-center">
        <img
          src="@/assets/images/vagader-illu-01.png"
          alt="friends"
          id="illu-vagader"
        />
        <img src="@/assets/images/picto/illu-ellipse.svg" alt="ellipse picto" />
      </div>
      <div class="container-right">
        <img src="@/assets/images/picto/illu-star.svg" alt="star" />
      </div>
    </section>
    <section class="identity last">
      <div class="wrapper">
        <div class="container-left">
          <h5>Travel</h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/vagader-id-01.jpg" alt="girl in a car" />
          <img src="@/assets/images/vagader-id-02.jpg" alt="man in a nest" />
        </div>
      </div>
      <div class="wrapper">
        <div class="container-left">
          <h5>Find locations</h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/vagader-id-03.jpg" alt="maps" />
          <img src="@/assets/images/vagader-id-04.jpg" alt="bike" />
        </div>
      </div>
      <div class="marquee">
        <div class="wrapper">
          <img
            src="@/assets/images/vagader-marquee-01.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-02.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-03.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-04.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-05.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-06.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-01.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/vagader-marquee-02.jpg"
            alt="marquee landscape"
          />
        </div>
      </div>
      <div class="wrapper">
        <div class="container-left">
          <h5>Organisation</h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/vagader-id-05.jpg" alt="woman working" />
          <img src="@/assets/images/vagader-id-06.jpg" alt="woman working" />
        </div>
      </div>
      <div class="wrapper">
        <div class="container-left">
          <h5>Communication</h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/vagader-id-07.jpg" alt="friends" />
          <img src="@/assets/images/vagader-id-08.jpg" alt="friends" />
        </div>
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    Ctago,
    Footer,
  },
  name: "Vagader",
  data() {
    return {
      cta: {
        width: 0,
        image: true,
        title: "Let's go !",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted: function () {
    if (this.width > 768) {
      const vagaderAnimationBig = this.vagaderAnimationBig();
    } else {
      const vagaderAnimationSmall = this.vagaderAnimationSmall();
    }
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    vagaderAnimationBig() {
      // HERO START
      var heroArray = [document.querySelectorAll(".hero .box")];
      heroArray.forEach((element, index) => {
        gsap.fromTo(
          element,
          { y: 20, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 2,
            stagger: 0.15,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });

      // HERO END
      // INTRO START
      gsap.fromTo(
        ".intro_title",
        { opacity: 0, y: 15 },
        {
          opacity: 1,
          y: 0,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".intro_title",
          },
        }
      );
      var introArray = document.querySelectorAll(".intro p");
      gsap.fromTo(
        introArray,
        { opacity: 0, y: 15 },
        {
          opacity: 1,
          y: 0,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: introArray,
          },
        }
      );
      // INTRO END
      // ART START
      var artVagader = [
        document.querySelector(".art .container-right img"),
        document.querySelector(".art .container-left img"),
      ];
      artVagader.forEach((image, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: -30,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        } else {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: 30,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        }
      });
      var imageArt = [document.querySelectorAll(".art .container-center img")];
      gsap.fromTo(
        imageArt,
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: imageArt,
          },
        }
      );
      // ART END
      gsap.fromTo(
        ".marquee",
        { xPercent: 10 },
        {
          xPercent: -30,
          ease: "sin.in",
          scrollTrigger: {
            scrub: 1,
            trigger: ".marquee",
            start: "bottom bottom",
            end: "+=1000 top",
          },
        }
      );
    },
    vagaderAnimationSmall() {
      // HERO START
      var heroArray = [document.querySelectorAll(".hero .box")];
      heroArray.forEach((element, index) => {
        gsap.fromTo(
          element,
          { y: 20, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 2,
            stagger: 0.15,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });
      // HERO END
      gsap.fromTo(
        ".marquee",
        { xPercent: 10 },
        {
          xPercent: -250,
          ease: "sin.in",
          scrollTrigger: {
            scrub: 1,
            trigger: ".marquee",
            start: "bottom bottom",
            end: "+=1000 top",
          },
        }
      );
      // INTRO START
      gsap.fromTo(
        ".intro_title",
        { opacity: 0, y: 15 },
        {
          opacity: 1,
          y: 0,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".intro_title",
          },
        }
      );
      var intro = document.querySelectorAll(".intro p");
      var introArray = Array.from(intro);
      introArray.forEach((element) => {
        gsap.fromTo(
          element,
          { opacity: 0, y: 15 },
          {
            opacity: 1,
            y: 0,
            ease: "sin.in",
            duration: 2,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });

      // INTRO END
      // ART START
      var artVagader = [
        document.querySelector(".art .container-right img"),
        document.querySelector(".art .container-left img"),
      ];
      artVagader.forEach((image, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: -150,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        } else {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: 150,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        }
      });
      var imageArt = [document.querySelectorAll(".art .container-center img")];
      gsap.fromTo(
        imageArt,
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: imageArt,
          },
        }
      );
      // ART END
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
