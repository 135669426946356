<template>
  <Navbar />
  <Smallnav />
  <router-view v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component"></component>
    </transition>
  </router-view>
</template>

<script>
import Navbar from "@/components/Navbar";
import Smallnav from "@/components/Smallnav";

export default {
  components: {
    Navbar,
    Smallnav,
  },
};
</script>


<style src="@/assets/scss/app.scss" lang="scss" />

