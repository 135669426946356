<template>
  <div>
    <section class="hero section">
      <div class="wrapper">
        <div class="box"><h1>INVOLVER</h1></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
        <div class="box"></div>
      </div>
    </section>
    <section class="intro section" id="intro">
      <h5 class="intro_title">
        Help and meet people, grow your business, develop your visibility...
      </h5>
      <p class="text-medium">
        You have a workspace, a cosy café, a sandwich shop, a place to sleep, a
        parking space, a perfect bar to spend the evening and you want to help
        and welcome people passing through your neighbourhood. Your role will be
        Involver.
      </p>
      <p class="text-medium">
        Use the application to promote your services and allow Vagaders to
        easily find their way around and enjoy the opportunity to meet new
        people.
      </p>
    </section>
    <section class="art section">
      <div class="container-left">
        <img src="@/assets/images/picto/illu-sunrise.svg" alt="sunrise" />
      </div>
      <div class="container-center">
        <!-- <div class="box-image"></div> -->
        <img src="@/assets/images/involver-illu-01.png" alt="restaurant" />
        <img src="@/assets/images/picto/illu-ellipse.svg" alt="ellipse" />
      </div>
      <div class="container-right">
        <img src="@/assets/images/picto/illu-star.svg" alt="star" />
      </div>
    </section>
    <section class="identity last">
      <div class="wrapper">
        <div class="container-left">
          <h5>
            Hosting and <br />
            meeting traveler
          </h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img
            src="@/assets/images/involver-id-01.jpg"
            alt="woman looking hot dog truck"
          />
          <img src="@/assets/images/involver-id-02.jpg" alt="food worker" />
        </div>
      </div>
      <div class="wrapper">
        <div class="container-left">
          <h5>
            Getting on<br />
            the map
          </h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img
            src="@/assets/images/involver-id-03.jpg"
            alt="customer service"
          />
          <img src="@/assets/images/involver-id-04.jpg" alt="cooking woman" />
        </div>
      </div>
      <div class="wrapper">
        <div class="container-left">
          <h5>Virtual showcase</h5>
        </div>
        <div class="container-center">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and xv 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H. Rackham.Contrary to popular belief, Lorem Ipsum is
            not simply random text. It has roots in a piece of classical Latin
            literature from 45 BC, making it over 2000 years old. Richard.
          </p>
        </div>
        <div class="container-right">
          <img src="@/assets/images/involver-id-05.jpg" alt="woman working" />
          <img src="@/assets/images/involver-id-06.jpg" alt="hairdresser" />
        </div>
      </div>
      <div class="marquee">
        <div class="wrapper">
          <img
            src="@/assets/images/involver-marquee-01.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-02.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-03.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-04.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-05.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-06.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-01.jpg"
            alt="marquee landscape"
          />
          <img
            src="@/assets/images/involver-marquee-02.jpg"
            alt="marquee landscape"
          />
        </div>
      </div>
    </section>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    Ctago,
    Footer,
  },
  name: "Involver",
  data() {
    return {
      width: 0,
      cta: {
        image: true,
        title: "Let's go !",
      },
    };
  },
  mounted: function () {
    if (this.width > 768) {
      const involverAnimationBig = this.involverAnimationBig();
    } else {
      const involverAnimationSmall = this.involverAnimationSmall();
    }
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    involverAnimationBig() {
      // HERO START
      var heroArray = [document.querySelectorAll(".hero .box")];
      heroArray.forEach((element, index) => {
        gsap.fromTo(
          element,
          { y: 20, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 2,
            stagger: 0.15,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });
      // HERO END
      gsap.fromTo(
        ".marquee",
        { xPercent: 10 },
        {
          xPercent: -30,
          ease: "sin.in",
          scrollTrigger: {
            scrub: 1,
            trigger: ".marquee",
            start: "bottom bottom",
            end: "+=1000 top",
          },
        }
      );
      // INTRO START
      gsap.fromTo(
        ".intro_title",
        { opacity: 0, y: 15 },
        {
          opacity: 1,
          y: 0,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".intro_title",
          },
        }
      );
      var introArray = document.querySelectorAll(".intro p");
      gsap.fromTo(
        introArray,
        { opacity: 0, y: 15 },
        {
          opacity: 1,
          y: 0,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: introArray,
          },
        }
      );
      // INTRO END
      // ART START
      var artInvolver = [
        document.querySelector(".art .container-right img"),
        document.querySelector(".art .container-left img"),
      ];
      artInvolver.forEach((image, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: -30,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        } else {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: 30,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        }
      });
      var imageArt = [document.querySelectorAll(".art .container-center img")];
      gsap.fromTo(
        imageArt,
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: imageArt,
          },
        }
      );
      // ART END
    },

    involverAnimationSmall() {
      // HERO START
      var heroArray = [document.querySelectorAll(".hero .box")];
      heroArray.forEach((element, index) => {
        gsap.fromTo(
          element,
          { y: 20, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 2,
            stagger: 0.15,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });
      // HERO END
      gsap.fromTo(
        ".marquee",
        { xPercent: 10 },
        {
          xPercent: -250,
          ease: "sin.in",
          scrollTrigger: {
            scrub: 1,
            trigger: ".marquee",
            start: "bottom bottom",
            end: "+=1000 top",
          },
        }
      );
      // INTRO START
      gsap.fromTo(
        ".intro_title",
        { opacity: 0, y: 15 },
        {
          opacity: 1,
          y: 0,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".intro_title",
          },
        }
      );
      var intro = document.querySelectorAll(".intro p");
      var introArray = Array.from(intro);
      introArray.forEach((element) => {
        gsap.fromTo(
          element,
          { opacity: 0, y: 15 },
          {
            opacity: 1,
            y: 0,
            ease: "sin.in",
            duration: 2,
            scrollTrigger: {
              trigger: element,
            },
          }
        );
      });

      // INTRO END
      // ART START
      var artInvolver = [
        document.querySelector(".art .container-right img"),
        document.querySelector(".art .container-left img"),
      ];
      artInvolver.forEach((image, index) => {
        if (index % 2 === 0) {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: -150,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        } else {
          gsap.fromTo(
            image,
            { yPercent: 0 },
            {
              yPercent: 150,
              scrollTrigger: {
                scrub: 1,
                trigger: image,
                end: "bottom+=300",
              },
            }
          );
        }
      });
      var imageArt = [document.querySelectorAll(".art .container-center img")];
      gsap.fromTo(
        imageArt,
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: imageArt,
          },
        }
      );
      // ART END
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
