<template>
  <div>
    <section class="hero section">
      <img
        id="largeHeroStory"
        src="@/assets/images/story-hero.png"
        alt="flags"
      />
      <img
        id="smallHeroStory"
        src="@/assets/images/story-hero-small.png"
        alt="flags"
      />
      <h2>We are vagade</h2>
      <h1>We are</h1>
      <h1>vagade</h1>
    </section>
    <section class="intro section">
      <h5>Discover new places, shape your own dream life, create moments...</h5>
      <p class="text-medium">
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced
        below for those interested. Sections 1.10.32 and 1.10.33 from "de
        Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact
        original form, accompanied by English versions from the 1914 translation
        by H.
      </p>
      <p class="text-medium">
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced
        below for those interested. Sections 1.10.32 and 1.10.33 from "de
        Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact
        original form, accompanied by English versions from the 1914 translation
        by H.
      </p>
      <p class="text-medium">
        The standard chunk of Lorem Ipsum used since the 1500s is reproduced
        below for those interested. Sections 1.10.32 and 1.10.33 from "de
        Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact
        original form, accompanied by English versions from the 1914 translation
        by H.
      </p>
    </section>
    <section class="vagade section">
      <div class="wrapper">
        <div class="box">
          <div class="title">
            <img src="@/assets/images/picto/illu-spark.svg" alt="spark" />
            <h4 class="left">VAG</h4>
            <h4 class="right">ADE</h4>
          </div>
          <div class="text">
            <img src="@/assets/images/picto/illu-sunrise.svg" alt="sunrise" />
            <p class="text-big">
              Vagade. Ce mot-valise est une fusion des mots vagabond et
              nomade.<br />
              Vagabond symbolise l’idée que l’on est retenu par rien. Nomade
              représente une personne en déplacement continuel.
            </p>
          </div>
        </div>
      </div>
      <div class="wrapper vagabond">
        <div class="container-left">
          <img src="@/assets/images/story-vagabond.png" alt="man on the road" />
        </div>
        <div class="container-right">
          <div>
            <h4>VAG<span>ABOND</span></h4>
            <div class="text">
              <p class="text-medium">(.adj)</p>
              <div class="definition">
                <p class="text-medium">
                  <span>1</span> - Qui erre, ne se fixe nulle part.
                </p>
                <p class="text-medium">
                  <span>2</span> - Qui voyage en permanence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper nomade">
        <div class="container-left">
          <img src="@/assets/images/story-nomade.png" alt="man on a desert" />
        </div>
        <div class="container-right">
          <div>
            <h4><span>NOM</span>ADE</h4>
            <div class="text">
              <p class="text-medium">(.adj)</p>
              <div class="definition">
                <p class="text-medium">
                  <span>1</span> - Qui ne reste pas longtemps au même endroit,
                  qui est caractérisé par des déplacements continuels.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="art section">
      <div class="wrapper">
        <div class="container-left">
          <img
            id="largeArtStory"
            src="@/assets/images/story-art-01.png"
            alt="art piece"
          />
          <img
            id="smallArtStory"
            src="@/assets/images/story-art-01-small.png"
            alt="art piece"
          />
        </div>
        <div class="container-right">
          <h3>A world without</h3>
          <h3>boundaries</h3>
        </div>
      </div>
    </section>
    <div class="start section">
      <h6>How it all started ?</h6>
      <h5>
        “I can't imagine spending all my time in one place. I feel the need to
        move around.”
      </h5>
      <div class="wrapper">
        <div class="container-left">
          <div class="picture">
            <img
              src="@/assets/images/story-start-01.jpg"
              alt="man on the mountain"
            />
            <img
              src="@/assets/images/story-start-02.jpg"
              alt="man on top of the mountain"
            />
          </div>
        </div>
        <div class="container-right">
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H.
          </p>
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H.
          </p>
          <p class="text-default">
            The standard chunk of Lorem Ipsum used since the 1500s is reproduced
            below for those interested. Sections 1.10.32 and 1.10.33 from "de
            Finibus Bonorum et Malorum" by Cicero are also reproduced in their
            exact original form, accompanied by English versions from the 1914
            translation by H.
          </p>
        </div>
      </div>
    </div>
    <div class="team last">
      <h4>THE TEAM</h4>
      <div class="wrapper">
        <div class="row">
          <h5>Jonathan</h5>
          <img
            class="bubble"
            src="@/assets/images/story-team-01.jpg"
            alt="Profile picture Johnny"
          />
          <h5>Founder</h5>
        </div>
      </div>
      <div class="wrapper">
        <div class="row">
          <h5>E.Lemal</h5>
          <img
            class="bubble"
            src="@/assets/images/story-team-02.jpg"
            alt="Profile picture johnny"
          />
          <h5>Mentor</h5>
        </div>
      </div>
      <div class="wrapper">
        <div class="row">
          <h5>C.Colasse</h5>
          <img
            class="bubble"
            src="@/assets/images/story-team-03.jpg"
            alt="Profile picture Emma"
          />
          <h5>Art ressource</h5>
        </div>
      </div>
      <div class="wrapper">
        <div class="row">
          <h5>I.Estercq</h5>
          <img
            class="bubble"
            src="@/assets/images/story-team-04.jpg"
            alt="Profile picture Emma"
          />
          <h5>Best assistant</h5>
        </div>
      </div>
      <div class="wrapper">
        <div class="row">
          <h5>Emma</h5>
          <img
            class="bubble"
            src="@/assets/images/story-team-05.jpg"
            alt="Profile picture Emma"
          />
          <h5>Moral support</h5>
        </div>
      </div>
    </div>
    <Ctago :cta="cta" />
    <Footer />
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Ctago from "@/components/Ctago";
import Footer from "@/components/Footer";

gsap.registerPlugin(ScrollTrigger);

export default {
  components: {
    Footer,
    Ctago,
  },
  name: "Story",
  data() {
    return {
      width: 0,
      cta: {
        image: true,
        title: "Are you seduced?",
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted: function () {
    if (this.width > 768) {
      const storyAnimationBig = this.storyAnimationBig();
    } else {
      const storyAnimationSmall = this.storyAnimationSmall();
    }
  },
  methods: {
    handleResize() {
      this.width = window.innerWidth;
    },
    storyAnimationBig() {
      // INTRO START
      var wrappers = gsap.utils.toArray([".intro h5", ".intro .text-medium"]);
      console.log(wrappers);
      wrappers.forEach((wrapper, index) => {
        // if (wrapper.classList.contains("invert")) {
        if (index % 2 === 0) {
          gsap.fromTo(
            wrapper,
            { xPercent: 10, opacity: 0 },
            {
              xPercent: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrapper,
              },
            }
          );
        } else {
          gsap.fromTo(
            wrapper,
            { xPercent: -10, opacity: 0 },
            {
              xPercent: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrapper,
              },
            }
          );
        }
      });
      // INTRO END
      // VAGADE START
      gsap.fromTo(
        ".vagade .title .left",
        { xPercent: -10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .title .left",
          },
        }
      );
      gsap.fromTo(
        ".vagade .title .right",
        { xPercent: 10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .title .left",
          },
        }
      );
      gsap.fromTo(
        ".vagade .text p",
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .text p",
          },
        }
      );
      gsap.fromTo(
        ".vagade .vagabond img",
        { xPercent: -10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .vagabond img",
            start: "top center",
          },
        }
      );
      gsap.fromTo(
        ".vagade .nomade img",
        { xPercent: 10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .nomade img",
            start: "top center",
          },
        }
      );
      // VAGADE END
      // ART START
      gsap.fromTo(
        ".art ",
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".art",
            start: "top-=100 center",
          },
        }
      );
      // ART END
      // STARTED START
      gsap.fromTo(
        ".start h6",
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 1,
          scrollTrigger: {
            trigger: ".start h6",
            start: "top center",
          },
        }
      );
      gsap.fromTo(
        ".start h5",
        { xPercent: 10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".start h5",
            start: "top center",
          },
        }
      );
      var elements = [
        document.querySelectorAll(".start .container-left .picture img"),
        document.querySelectorAll(".start .container-right .text-default"),
      ];
      elements.forEach((element, index) => {
        gsap.fromTo(
          element,
          { x: 100, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 1,
            stagger: 0.15,
            scrollTrigger: {
              trigger: ".start .container-left",
              start: "top center",
            },
          }
        );
      });
      // STARTED END
      // TEAM START
      var viewportWidth = this.width - 240 - 118;
      // console.log(viewportWidth);
      var bubbles = [document.querySelectorAll(".bubble")];
      bubbles.forEach((bubble, index) => {
        gsap.fromTo(
          bubble,
          { x: 0 },
          {
            x: -viewportWidth,
            ease: "sin.in",
            stagger: 0.1,
            scrollTrigger: {
              scrub: 1,
              trigger: ".team",
              start: "top center",
              end: "bottom center",
            },
          }
        );
      });
      // TEAM END
    },
    storyAnimationSmall() {
      // INTRO START
      var wrappers = gsap.utils.toArray([".intro h5", ".intro .text-medium"]);
      console.log(wrappers);
      wrappers.forEach((wrapper, index) => {
        // if (wrapper.classList.contains("invert")) {
        if (index % 2 === 0) {
          gsap.fromTo(
            wrapper,
            { yPercent: 10, opacity: 0 },
            {
              yPercent: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrapper,
              },
            }
          );
        } else {
          gsap.fromTo(
            wrapper,
            { yPercent: 10, opacity: 0 },
            {
              yPercent: 0,
              opacity: 1,
              ease: "sin.in",
              duration: 2,
              scrollTrigger: {
                trigger: wrapper,
              },
            }
          );
        }
      });
      // INTRO END
      // VAGADE START
      gsap.fromTo(
        ".vagade .title .left",
        { xPercent: -10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .title .left",
          },
        }
      );
      gsap.fromTo(
        ".vagade .title .right",
        { xPercent: 10, opacity: 0 },
        {
          xPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .title .left",
          },
        }
      );
      gsap.fromTo(
        ".vagade .text p",
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .text p",
          },
        }
      );
      gsap.fromTo(
        ".vagade .vagabond img",
        { yPercent: 10, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .vagabond img",
            start: "top center",
          },
        }
      );
      gsap.fromTo(
        ".vagade .nomade img",
        { yPercent: 10, opacity: 0 },
        {
          yPercent: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".vagade .nomade img",
            start: "top center",
          },
        }
      );
      // VAGADE END
      // ART START
      gsap.fromTo(
        ".art ",
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".art",
            start: "top-=100 center",
          },
        }
      );
      // ART END
      // STARTED START
      gsap.fromTo(
        ".start h6",
        { opacity: 0 },
        {
          opacity: 1,
          ease: "sin.in",
          duration: 1,
          scrollTrigger: {
            trigger: ".start h6",
            start: "top center",
          },
        }
      );
      gsap.fromTo(
        ".start h5",
        { y: 10, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          ease: "sin.in",
          duration: 2,
          scrollTrigger: {
            trigger: ".start h5",
            start: "top center",
          },
        }
      );
      var elements = [
        document.querySelectorAll(".start .container-left .picture img"),
        document.querySelectorAll(".start .container-right .text-default"),
      ];
      elements.forEach((element, index) => {
        gsap.fromTo(
          element,
          { y: 10, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            ease: "sin.in",
            delay: index * 1,
            duration: 1,
            stagger: 0.15,
            scrollTrigger: {
              trigger: ".start .container-left",
              start: "top center",
            },
          }
        );
      });
      // STARTED END
      // TEAM START
      var viewportWidth = this.width - 36 - 60;
      // console.log(viewportWidth);
      var bubbles = [document.querySelectorAll(".bubble")];
      bubbles.forEach((bubble, index) => {
        gsap.fromTo(
          bubble,
          { x: 0 },
          {
            x: -viewportWidth,
            ease: "sin.in",
            stagger: 0.1,
            scrollTrigger: {
              scrub: 1,
              trigger: ".team ",
              start: "top center",
              end: "bottom center",
            },
          }
        );
      });
      // TEAM END
    },
  },
};
</script>
<style src="./style.scss" lang="scss" scoped />
