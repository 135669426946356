import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home/Home.vue'
// import Vagader from '../views/Vagader/Vagader.vue'
// import Involver from '../views/Involver/Involver.vue'
// import Story from '../views/Story/Story.vue'
// import Features from '../views/Features/Features.vue'
// import Price from '../views/Price/Price.vue'
// import Contact from '../views/Contact/Contact.vue'
// import Login from '../views/Login/Login.vue'
// import Download from '../views/Download/Download.vue'
// import Playground from '../views/Playground/Playground.vue'
import Home from '../views/Home/Home.vue'
import Vagader from '../views/Vagader/Vagader.vue'
import Involver from '../views/Involver/Involver.vue'
import Story from '../views/Story/Story.vue'
import Features from '../views/Features/Features.vue'
import Price from '../views/Price/Price.vue'
import Contact from '../views/Contact/Contact.vue'
import Login from '../views/Login/Login.vue'
import Download from '../views/Download/Download.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/vagader',
    name: 'Vagader',
    component: Vagader
  },
  {
    path: '/involver',
    name: 'Involver',
    component: Involver
  },
  {
    path: '/story',
    name: 'Story',
    component: Story
  },
  {
    path: '/features',
    name: 'Features',
    component: Features
  },
  {
    path: '/price',
    name: 'Price',
    component: Price
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  },
  // {
  //   path: '/playground',
  //   name: 'Playground',
  //   component: Playground
  // }
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
          document.getElementById('app').scrollIntoView()
      }
})

export default router
